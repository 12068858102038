import assetify from "@quintype/framework/assetify";
import get from "lodash/get";
import { object } from "prop-types";
import React from "react";
import Arrow1 from "../../basic/images/slide-prev-dark-icon.svg";
import DfpAd from "../../dfp-ad";
import CardImageType3 from "../../molecules/card-image-type-3";
import { RowTitle } from "../../molecules/row-title";
import Carousel from "./carousel";
import "./feature-slider.m.css";

const FeatureSliderRow = ({ collection = {} }) => {
  const storiesFromCollection = collection.items.filter(item => item.type === "story");
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
    const children = storiesFromCollection.map((story, key) => {
    const storyObj = story.story ? story.story : story;
    return <div key={key}>{story && <CardImageType3 story={storyObj} showSection />}</div>;
  });

  return (
    <div styleName="wrapper">
      <RowTitle collection={collection} />

      <div styleName="carousel-container">
        <Carousel
          carouselName={`carousel-${collection.slug}`}
          options={{
            type: `${storiesFromCollection.length <= 4 ? "slider" : "carousel"}`,
            perView: 5,
            gap: 24,
            breakpoints: { 480: { perView: 1, peek: { before: 0, after: 80 } } }
          }}
          totalItems={storiesFromCollection.length}
          showArrows={true}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => {
            return (
              currentSlide !== 0 && (
                <button onClick={previousSlide} className="slider-btn">
                  <img src={`${assetify(Arrow1)}`} alt="left" />
                </button>
              )
            );
          }}
          renderCenterRightControls={({ nextSlide }) =>
            storiesFromCollection.length > 4 && (
              <button onClick={nextSlide} className="slider-btn">
                <img src={`${assetify(Arrow1)}`} alt="right" styleName="next-arrow" />
              </button>
            )
          }
        >
          {children}
        </Carousel>
      </div>

      {adConfig && (<div styleName="ads">Ad Space in FeatureSliderRow</div>)}
    </div>
  );
};

FeatureSliderRow.propTypes = {
  collection: object
};

export { FeatureSliderRow };

FeatureSliderRow.storyLimit = 12;

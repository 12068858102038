/* eslint-disable import/extensions */
import React, { useState, useEffect, useRef } from "react";
import { get } from "lodash";
import PT from "prop-types";
import { StoryCard } from "@quintype/arrow";
import { Collection } from "@quintype/components";
import { getCollectionTemplate } from "../../get-collection-template";
import { OneBigImgNewsCols } from "../../rows/one-big-img-news-cols";
//import Suosituimmat from "../../story-templates/suosituimmat";
import EditorialCategory from "../../story-templates/editorial-category";
import FromReaderCategory from "../../story-templates/fromreader-category";
import { noOfPages } from "../../utils/noOfPages";
import { changQueryString } from "../tag/index";
import TimeStamp from "../../atoms/time-stamp";
import CardImage from "../../atoms/card-image";
import { Link } from "@quintype/components";
import ColumnCategory from "../../story-templates/column-category";
import CardImageType8 from "../../molecules/card-image-type-8";
import { Suurtaulu } from "../../atoms/suurtaulu";
import { Lounaspaikka } from "../../atoms/lounaspaikka";
import StarShowHide from "../../atoms/star-show-hide";
import { ShowMoreBtn } from "../../atoms/show-more-btn";
import SectionShowMoreStories from "../../molecules/section-show-more-stories";
import "./tag.m.css";
import Uusimmat from "../../story-templates/uusimmat";

const storiesDom = (stories, startIndex, newestCategory) => {
  return stories.map((story, index) => {
    const storyObj = story.story || story;

    /* getting url right regarding different papers*/

    function findByKey(key, value) {
      return (item, i) => item[key] === value
    }

    let findParams = findByKey("domain-slug", "komiat") //change here
    let positionOfPaper = storyObj.sections.findIndex(findParams)

    var parts = storyObj.slug.lastIndexOf("/");
    var storySlug = storyObj.slug.substring(parts+1);

    // Check if there is section for that paper
    if (storyObj.sections[positionOfPaper]){
      var splitUrl = storyObj.sections[positionOfPaper]["section-url"].split("/"); //split when slash
      var joinUrl = splitUrl.slice(3); //slice after third slug
      var finalUrl = joinUrl.join("/"); //join the remaining parts with slash
      //console.log("final url " + finalUrl + "/" + storySlug);
    }

    return (

      <React.Fragment key={index}>

      {story["hero-image-s3-key"] !== null ?
                    <div className="item" key={story.id}>
                      <CardImageType8 story={story} showSubHeadline={false} />
                    </div> :

                    <div className="main-headline item" styleName={story["hero-image-s3-key"] ? "story-content" : "story-content-no-img"}>
                      <Link href={
                          positionOfPaper !== -1 ? (
                          storyObj.sections[positionOfPaper] ? (
                          `/${finalUrl}/${storySlug}`
                          ) : (
                          `/${storyObj.sections[positionOfPaper].slug}/${storySlug}`
                          )
                          ) : (
                          `/${storyObj.slug}`
                          )
                          }>
                      <div styleName="wrapper-inside" className="wrapper-inside">

                        <h2 styleName="" className="headline-layout4 main-headline-layout1 layout4">
                          <span styleName="span-newest">{story.metadata && story.metadata["pääaihe"] && `${story.metadata["pääaihe"]} | `}</span>
                          {story.headline}
                        </h2>
                        <div styleName="section-time-wrapper">
                          <div className="time-stamp-layout1">
                            <TimeStamp timeStamp={story["published-at"]} />
                          </div>
                          <StarShowHide accessType={story.access} />
                        </div>
                      </div>
                      </Link>
                    </div>
                  }
      </React.Fragment>
    );
  });
};

const TagPage = props => {
  const { data } = props;
  const [tagStories, setTagStories] = useState([]);
  const storiesWithTag = [...tagStories || null];
  const storiesWithTag30 = storiesWithTag.slice(0, 30);
  const [storyLimit, setStoryLimit] = useState(30);
  const onItemsToLoadClick = ()=> setStoryLimit(storyLimit + 5);

  useEffect(() => {
    async function fetchAPI() {
      const stories = await fetch(`/api/v1/advanced-search?tag-name-lower=${data.title.toLowerCase()}&limit=80&section-id=${data.sectionIds}&sort=latest-published`)
      const response = await stories.json();
      const resultData = response.items;
      setTagStories(resultData);
    }
    fetchAPI();
  }, [])

  return (
    <div styleName="top-container">
      <div styleName="container">
        <div styleName="main">
          <div styleName="ad-container">
            <div  styleName="mob-ad">
              <div id="sas_63333"></div>
            </div>
            <div styleName="dsktop-ad">
            <div id="sas_63219"></div>
          </div>
        </div>
        {/*<div styleName="ad-container">
          <div styleName="mob-ad">Ad Position 1 <br /> Top Ad 300*300</div>
          <div id="sas_63219" styleName="dsktop-ad"></div>
        </div>*/}

      </div>
      <div styleName="lazy-pop">
        <div styleName="section-content">
          <div styleName="section-top">
            <div styleName="section-title">
            {decodeURIComponent(data.title)}
            </div>
            <div styleName="section-line"></div>
          </div>
          <div styleName="bottom-stories-with-sidebar">
              {
                storiesWithTag.length > 0 ?
                  storiesDom(storiesWithTag30) :
                <h2 styleName="no-story-headline">Ei artikkeleita...</h2>
              }
              {<SectionShowMoreStories stories={storiesWithTag} startIndex={30} itemsToLoad={storyLimit} />}
              {
                storiesWithTag.length > 30 ?
                  <ShowMoreBtn loadMore={onItemsToLoadClick} btnName="NÄYTÄ LISÄÄ" /> :
                null
              }
            </div>
        </div>
        <div styleName="popular">
          {/*<div styleName="sidebar-ad-placeholder">Sidebar Ad 300 * 600(Pystyparaati)</div>*/}
          <div styleName="popular-grid">
          <div styleName="sidebar-ad" id="sas_63222"></div>
          {/*<div styleName="mobile-hide-popular">
              <Suosituimmat />
          </div>*/}
          <Uusimmat />
              <EditorialCategory />
              <ColumnCategory />
              <FromReaderCategory />
          </div>
        </div>
        </div>
      </div>
      <Suurtaulu />
    </div>
  );
};

TagPage.propTypes = {
  data: PT.object,
  onLoadMore: PT.func,
  stories: PT.array,
  noMoreStories: PT.bool
};

export { TagPage };

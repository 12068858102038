import React from "react";
import { FourColGrid } from "@quintype/arrow";
import PT from "prop-types";

export const FourColGridRow = ({ collection }) => {
  const contextConfig = {
    theme: "#F5F5F5",
    showSection: true,
    showAuthorTime: true,
    showSubheadline: true,
    showRowTitle: true,
    showFooterButton: true,
    buttonText: "Näytä lisää",
    collectionNameTemplate: "default",
    sectionTagTemplate: "default",
  };
 return (
 <div className="container">
    {collection && (
    <FourColGrid
      collection={collection}
      config={contextConfig}
      />
      )}
  </div>
  )
};

FourColGridRow.propTypes = {
  collection: PT.object,
  config: PT.object
};

import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { collectionToStories, Link } from "@quintype/components";
import { StoryCard, SectionTag } from "@quintype/arrow";
import CardImageType2 from "../../molecules/card-image-type-2";
import CardImageType1 from "../../molecules/card-image-type-1";
import CardImageType3 from "../../molecules/card-image-type-3";

import "./no-image-news-cards.m.css";
const NoImgNewsCards = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const storiesFromCollection = collectionToStories(collection);

  if (storiesFromCollection.length < 1) {
    return null;
  }

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
            <div styleName="first-story">
              {storiesFromCollection.map((stories,index)=>
                <React.Fragment key={index}>
                <div className="story-content">
                  <Link href={`/${stories.slug}`}>

                      <SectionTag story={stories} />
                      <h3 className="story-headline">{stories.headline}</h3>

                  </Link>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

NoImgNewsCards.propTypes = {
  collection: object
};

export { NoImgNewsCards };

NoImgNewsCards.storyLimit = 12;

import React from "react";
import get from "lodash/get";
import { object } from "prop-types";

import { collectionToStories, Link } from "@quintype/components";

import CardImageType4 from "../../molecules/card-image-type-4";

import "./six-small-news-img-headline.m.css";
import { SectionCategoryTitle } from "../../molecules/section-category-title";

const SixSmallNewsImgNHeadline = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  const storiesFromCollection = collectionToStories(collection);
  //console.log("mycollection", collection)
  if (storiesFromCollection.length < 1) {
    return null;
  }
  return (
    <React.Fragment>

          <div styleName="inner-wrapper1">
          {showSection &&<div styleName="collection-name"><SectionCategoryTitle name={collection.name} /></div>}
            <div styleName="item-wrapper1">
              {storiesFromCollection.length >= 1 &&
                storiesFromCollection.slice(0, 6).map((item, index) =>
                  <React.Fragment key={index}>
                    <div styleName="item" className ="item" key={item.id}>
                      <CardImageType4 story={item} />
                    </div>
                  </React.Fragment>
                )
              }
            </div>
          </div>
    </React.Fragment>
  );
};

SixSmallNewsImgNHeadline.propTypes = {
  collection: object
};
export { SixSmallNewsImgNHeadline };

SixSmallNewsImgNHeadline.storyLimit = 22;

import React from "react";

import { SearchBoxBase } from "./search-box.js";
import "./search-box.m.css";

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchFormOpen: false,
      initialized: false
    };
    this.textInput = React.createRef();
    this.textInputMob = React.createRef();
  }

  componentDidMount() {
    this.setState({
      initialized: true
    });
  }

  toggleSearchForm = () => {

    this.setState(() => {
      this.textInput.current.focus();
      this.textInputMob.current.focus();
    });
  };

  render() {
    const formStyle = {
      display: this.state.isSearchFormOpen
    };
    const initialStyle = {
      opacity: this.state.isSearchFormOpen
    };
    const searchStyle = {
      transform: this.state.isSearchFormOpen
    };

    return (
      <React.Fragment>
        <div styleName="searchbox-on-page-text">Etsi hakusanalla</div>
          <SearchBoxBase
            formStyle={formStyle}
            searchStyle={searchStyle}
            initialStyle={initialStyle}
            //id="formDesk"
            innerRef={this.textInput}
            ref={null}
          />
      </React.Fragment>
    );
  }
}

export { SearchBox };

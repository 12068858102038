import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { collectionToStories } from "@quintype/components";
import "./native-ad-layout.m.css";
import CardImageTypeNative from "../../molecules/card-image-type-native";
const NativeAdLayout = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }

  const [nativeAdObj] = storiesFromCollection || [];

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
            <div styleName="first-story first-story1">
              <CardImageTypeNative story={nativeAdObj} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

NativeAdLayout.propTypes = {
  collection: object
};

export { NativeAdLayout };

NativeAdLayout.storyLimit = 12;

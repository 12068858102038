import React, { useState, useRef, useEffect } from "react";
import { get } from "lodash";
import PT from "prop-types";

import { HeroImage, StoryCard } from "@quintype/arrow";
import { Collection } from "@quintype/components";


import { OneBigImgNewsCols } from "../../rows/one-big-img-news-cols";
import { noOfPages } from "../../utils/noOfPages";
import "./native-ad-component.m.css";
import { NativeAdLayout } from "../../rows/native-ad-layout";
const NativeAdStory = () => {

  return (
    <div styleName="">
<NativeAdLayout />
    </div>
  );
}

export { NativeAdStory };
import React, { useEffect, useState } from 'react';

import './suurtaulu-frontpage.m.css';

const EtusivuSuurtaulu = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isTop, setIsTop] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 4000;
    let heightToShow = 2000;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
      //console.log("winScroll scroll up will increase numbers", winScroll);
    if (winScroll > heightToShow) {
      setIsTop(true);
    } else {
      setIsTop(false);
    }
    if (winScroll > heightToHideFrom) {
      isVisible &&
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
    return (
      <div styleName="ad-container-side" className="ad-container-side">
    <div styleName="sticky1">
      <div styleName="pidennettysuurtaulu1">
      <div id="sas_63225"></div>

       </div>
     </div>
     <div styleName="sticky2">
       <div styleName="pidennettysuurtaulu2">
       <div id="sas_63226"></div>

       </div>
     </div>
      </div>
    )
}
export { EtusivuSuurtaulu };

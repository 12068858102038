import get from "lodash.get";

export const getSlug = (collection, config) => {
  const isSection = collection.template === "section";

  if (isSection) {
    const sectionId = get(collection, ["metadata", "section", "0", "id"], "");

    const { sections } = config;
    const section = sections && sections.find(section => section.id === sectionId);
    return section ? section["section-url"] : "/";
  }
  return `/collection/${collection.slug}`;
};




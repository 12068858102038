import React from "react";
import PropTypes from "prop-types";

import "./load-more.m.css";

export const LoadmoreButton = ({ onClick, btnText = "Lue lisää" }) => {
  return (
    <>
      <button className="arr--button" styleName="button" onClick={onClick}>
        {btnText}
      </button>
    </>
  );
};

LoadmoreButton.propTypes = {
  onClick: PropTypes.func,
  btnText: PropTypes.string
};

import { FullScreenSlider } from "@quintype/arrow";
import get from "lodash.get";
import { object } from "prop-types";
import React from "react";

import "./full-screen-slider-row.m.css";

const FullScreenSliderRow = ({ collection, publisherConfig }) => {
  const contextConfig = {
    theme: "white",
    isborder: true,
    showSection: false,
    showAuthorTime: true,
    showSubheadline: true,
    showRowTitle: true,
    showFooterButton: false,
    buttonText: "Näytä lisää",
    autoplaySpeed: 6000,
    slideIndicator: "dots",
    navigationArrows: false,
    collectionTemplate: collection.slug,
    collectionNameTemplate: "default",
    sectionTagTemplate: "borderLeft",
    numberOfStoriesToShow: 10,
    isFullWidth: false,
    contentAlignment: "center",
    footerSlotConfig: { footerSlot: () => <div styleName="footerSlot">{`Koko kuvanäkymä`}</div> }

  };
  //footerSlotConfig: { footerSlot: () => <div styleName="footerSlot">{`Koko kuvanäkymä ${collection.slug}lta`}</div> }
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);

  return (
    <div styleName="wrapper">
      {collection && (
        <FullScreenSlider
          collection={collection}
          config={contextConfig}
          publisherConfig={publisherConfig}

        />
      )}
    </div>
  );
};

FullScreenSliderRow.propTypes = {
  collection: object,
  publisherConfig: object,
  stories: object,
  associatedMetadata: object,
  borderColor: object,
  summary: String
};

export { FullScreenSliderRow };

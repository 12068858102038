import React from "react";
import { func, array, bool } from "prop-types";
import CardImageType9 from "../card-image-type-9";
import PT from 'prop-types';

import "./pagination-story.m.css";

const PaginationStory = ({ story }) => {
  //console.log("STOORI",story);
  return (
    <div styleName="story-card">
      <div className="item">
        <CardImageType9 story={story} showSubHeadline ={false}/>
      </div>
  </div>

  );
};

export default PaginationStory;

PaginationStory.propTypes = {

  story: array
};

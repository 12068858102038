import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { SectionTag } from "@quintype/arrow";
import { collectionToStories, Link } from "@quintype/components";
import CardImageType2 from "../../molecules/card-image-type-2";

import "./small-image-news-cards.m.css";

const SmallImgNewsCards = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const storiesFromCollection = collectionToStories(collection);

  if (storiesFromCollection.length < 1) {
    return null;
  }
  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
            <div styleName="item-wrapper1">
              {storiesFromCollection.length >= 1 &&
                storiesFromCollection.slice(0,1).map((item, index) =>
                  <React.Fragment key={index}>
                    <div styleName="item" key={item.id}>
                    <SectionTag story={item} />
                      <CardImageType2 story={item}/>
                    </div>
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

SmallImgNewsCards.propTypes = {
  collection: object
};

export { SmallImgNewsCards };

SmallImgNewsCards.storyLimit = 12;

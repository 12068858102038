import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { object, array } from "prop-types";
import { Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";

import { getSlug } from "../../../components/utils";
import ArrowRight from "../../basic/images/caret-right.svg";

import "./row-title.m.css";

const RowTitleBase = ({ collection, config, menuItem }) => {
  const getMenuId = get(collection, ["associated-metadata", "menu_id"], "");
  const titleConfig = get(collection, ["associated-metadata", "show_title"], true);

  const getSubMenu = (menuItem, id) => {
    return menuItem.filter(item => {
      if (item["menu-group-id"] === id) {
        return true;
      }
      return false;
    });
  };

  const slug = getSlug(collection, config);
  const getMenuItem = getSubMenu(menuItem, getMenuId);
  return (
    titleConfig && (
      <div styleName="wrapper" className="topmar">
        <h3 styleName="title" className="row-title">
          {`${collection.name} - ${collection.type}`}
        </h3>
        <div styleName="read-more-wrapper">

            <React.Fragment>
             {/* <Link styleName="read-more dropdown" className="read-more">
              Lue lisää <img src={`${assetify(ArrowRight)}`} alt="notification" />
              </Link>*/}
              <ul styleName="dropdown-list">
                {getMenuItem.map((item, index) => {

                  return item.isExternalLink ? (
                    <li key={index}>
                      <a target="_blank" styleName="list-item" rel="noopener noreferrer" href={item.completeUrl}>
                        {item.title}{" "}
                      </a>
                    </li>
                  ) : (
                    <li key={index}>
                      <Link styleName="list-item" href={`${item.completeUrl}`}>
                        {item.title}{" "}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>

        </div>
      </div>
    )
  );
};

function mapStateToProps(state) {
  return {
    config: get(state, ["qt", "config"], {}),
    menuItem: get(state, ["qt", "data", "navigationMenu", "menuItems"], [])
  };
}

RowTitleBase.propTypes = {
  collection: object,
  config: object,
  menuItem: array
};

export const RowTitle = connect(mapStateToProps)(RowTitleBase);

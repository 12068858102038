import React from "react";
import PT from "prop-types";
import DOMPurify from 'dompurify';

import assetify from "@quintype/framework/assetify";
import SearchIcon from "../../basic/images/search.svg";

import "./search-box.m.css";

class SearchBoxBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        name: "",
    };
  }
  handleChange = (e) => {
    const { value } = e.target
    const cleanInput = DOMPurify.sanitize(value);
    this.setState({
        name: cleanInput.replace(/ /g, "-")
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    window.location = `/search?q=${this.state.name}`;
  }
  render() {
    return (
      <React.Fragment>
        <div id="searchBox" styleName="qt-search-form" style={this.props.formStyle} className="qt-search-forms">

            <form onSubmit={this.handleSubmit} method="get">
              <div styleName="input-group">
                <input
                  id={this.props.id}
                  ref={this.props.innerRef}
                  name='name'
                  value={this.state.name}
                  styleName="searchpage-search"
                  placeholder="Hae..."
                  name="q"
                  required
                  onChange={e => this.handleChange(e)}
                />
              </div>
              <span className="input-group-btn">
                  <button type="submit" styleName="btn-search">
                    <i className="ri-search-line"></i><span styleName="search-text">Hae</span>
                  </button>
              </span>
            </form>

        </div>
      </React.Fragment>
    );
  }
}
export { SearchBoxBase };

SearchBoxBase.propTypes = {
  formStyle: PT.object,
  initialStyle: PT.object,
  searchStyle: PT.object,
  id: PT.string,
  toggleSearchForm: PT.func,
  innerRef: PT.object
};

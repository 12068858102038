import React from "react";
import { func, array, bool } from "prop-types";
import CardImageType6 from "../card-image-type-6";
import PT from 'prop-types';

import "./stories-for-load-more.m.css";

const Stories = ({ stories, itemsToLoad }) => {
  return (
    <div>
      {stories.slice(0,itemsToLoad).map((items, index) => {
        const item = items.story || items;

        return (
          <div styleName="story-card" key={index}>
            <React.Fragment key={index}>
              <div className="item" key={item.id}>
                <CardImageType6 story={item} showSubHeadline ={false}/>
              </div>
            </React.Fragment>
          </div>
        );
      })}
    </div>
  );
};

export default Stories;

Stories.propTypes = {
  onLoadMore: func,
  stories: array,
  noMoreStories: bool,
  itemsToLoad: PT.number
};

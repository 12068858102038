import { TwoColFourStories } from "@quintype/arrow";
import get from "lodash.get";
import { object } from "prop-types";
import React from "react";
import DfpAd from "../../dfp-ad";
import "./two-col-four-stories-row.m.css";

 const TwoColFourStoriesRow = ({ collection, publisherConfig}) => {
  const contextConfig = {

    showSection: true,
    showAuthorTime: true,
    showSubheadline: true,
    showRowTitle: true,
    showFooterButton: true,
    buttonText: "Näytä lisää",
    collectionNameTemplate: "default",
    sectionTagTemplate: "default",
  };

  const dfpConfig = get(collection, ["associated-metadata", "show_dfp"], true);
  return (
    <div styleName="wrapper">
      {collection && (
        <TwoColFourStories
          collection={collection}
          config={contextConfig}
          publisherConfig={publisherConfig}

        />
      )}
    </div>
  );
};

TwoColFourStoriesRow.propTypes = {
  collection: object,
  publisherConfig: object
};

export  {TwoColFourStoriesRow};

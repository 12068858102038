/* eslint-disable import/extensions */
import PT from "prop-types";
import React, { useState } from "react";
//import Suosituimmat from "../../story-templates/suosituimmat";
import EditorialCategory from "../../story-templates/editorial-category";
import ColumnCategory from "../../story-templates/column-category";
import FromReaderCategory from "../../story-templates/fromreader-category";
import { SearchBox } from "../../molecules/search-box"
import LoadMoreSearchResults from "../../molecules/load-more-search-results";
import { LoadmoreButton } from "../../atoms/Loadmore-btn";
import _ from 'lodash';
import "./search.m.css";
import Uusimmat from "../../story-templates/uusimmat";

const SearchPage = props => {
  const { data } = props;
  const { stories } = props.data;
  console.log("storiesx SearchPage", stories);
  const ordered = _.sortBy(stories, [function (o) {
    return o["published-at"];
  }])
  const orderedStories = ordered.reverse();
  const queryhelper = data.query.replace(/-/g, " ");
  const [toggle, setToggle] = useState(false);
  const [byRelativity, setByRelativity] = useState(false);
  const [toggleBoth, setToggleBoth] = useState(false);
  const sortByDate = () => {
    setToggle(true)
    setByRelativity(false)
    setToggleBoth(true)

  }
  const [storiesData, setStoriesData] = useState();
  const [storyLimit, setStoryLimit]= useState(20);
  const onItemsToLoadClick = ()=> setStoryLimit(storyLimit+5);
  const sortByRelativity = async () => {
    const stories = await fetch(`/api/v1/advanced-search?q=${data.query}&limit=2000&sort=score`)
    const response = await stories.json();
    const resultData = response.items;
    setStoriesData(resultData);
    setByRelativity(true)
    setToggle(false)
    setToggleBoth(false)
  }

  return (
    <div styleName="wrapper">
      <div styleName="result-body">
        <div styleName="search-content">
          <h1 styleName="headline">{`Hae sivulta`} </h1>
          <div styleName="search-box"><SearchBox /></div>
          <div styleName="search-sorting">
            <div styleName="sort-firstrow">
              <div styleName="sort-heading">Hakutulokset:</div>
              <div styleName="sort-count">
                <div styleName="count-query">"{queryhelper || ""}"</div>
                <div styleName="count-total">{byRelativity ? storiesData.length : data.stories.length || "0"}</div>
                <div styleName="count-hits">osumaa</div>
              </div>
            </div>
          </div>
          {<LoadMoreSearchResults toggleBoth={toggleBoth} byRelativity={byRelativity} storiesData={storiesData} data={data} orderedStories={orderedStories} storyLimit={storyLimit} isOrdered={toggle} sortByDate={() => sortByDate()} sortByRelativity={() => sortByRelativity()} />}
          {orderedStories.length>9?
          <div styleName="loadmore"><LoadmoreButton onClick={()=>onItemsToLoadClick()} btnText="Näytä lisää" /></div>:null}
        </div>
        <div styleName="popular">
          <div styleName="popular-grid">
              {/*<Suosituimmat />*/}
              <Uusimmat />
              <EditorialCategory />
              <ColumnCategory />
              <FromReaderCategory />
          </div>
        </div>
      </div>
    </div>
  );
};

SearchPage.propTypes = {
  data: PT.object
};

export { SearchPage };

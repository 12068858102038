import React from "react";
import get from "lodash.get";
import PropTypes from "prop-types";

import assetify from "@quintype/framework/assetify";
import { ResponsiveImage } from "@quintype/components";

import User from "../../basic/images/user.svg";

import "./author-image.m.css";

export const AuthorImage = ({ story, width = 100 }) => {
  const { "avatar-url": avatarUrl, "avatar-s3-key": avatarS3Key, name: authorName } = get(story, ["authors", "0"], "");

  return (
    <div styleName="wrapper" className="author-image">
      <figure
        styleName="author-image-container"
        style={{
          width: width + "px",
          height: width + "px"
        }}
      >
        {avatarS3Key ? (
          <ResponsiveImage
            slug={avatarS3Key}
            aspect-ratio={[1, 1]}
            defaultWidth={400}
            imgParams={{ auto: ["format", "compress"], q: width }}
            alt={authorName}
            styleName="image"
          />
        ) : (
          <img styleName="image" src={avatarUrl ? avatarUrl : assetify(User)} />
        )}
      </figure>

    </div>
  );
};

AuthorImage.propTypes = {
  story: PropTypes.object.isRequired,
  width: PropTypes.string
};

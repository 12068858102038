import { Headline, HeroImage, SectionTag, StoryCard } from "@quintype/arrow";
import { collectionToStories } from "@quintype/components";
import get from "lodash/get";
import { object, bool } from "prop-types";
import React from "react";
import DfpAd from "../../dfp-ad";
import { RowTitle } from "../../molecules/row-title";
import "./three-col-fifteen-stories-row.m.css";

const ThreeColFifteenStoriesRow = ({ collection, dfpAd }) => {
  //console.log("collection A", collection)
  const items = collectionToStories(collection);
  //console.log("items A", items)
  if (items.length < 1) {
    return null;
  }

  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const configurableSlot = () => {
    return (
      <div styleName="dfp">
        <DfpAd
          adtype="xxxx"
          collectionSlug={collection.slug}
          slotId={`threeColFourteenStoriesRow-${collection.slug}`}
        />
      </div>
    );
  };

  const [firstStory, secondStory, thirdStory, ...restOftheStories] = items || [];

  const storyCallBack = (restOftheStories = []) => {
    return restOftheStories.slice(9, 12).map(item => {
      return (
        <StoryCard
            story={item}
            key={item}
            headerLevel="2"
            border="bottom"
            isHorizontal
            aspectRatio={[
              [1, 1],
              [4, 3]
            ]}>
            <HeroImage story={item} aspectRatio={[[4, 3]]} />
            <div styleName="small-card-content">
                <SectionTag story={item} />
                <Headline story={item} headerLevel="6" />
            </div>
        </StoryCard>

      );
    });
  };

  return (
    <div styleName="wrapper" className="three-col-fourteen-stories">
      <RowTitle collection={collection} />

      {/* {collection && <ThreeColFourteenStories collection={collection} config={contextConfig} />} */}
      <div className="full-width-with-padding arrow-component">
        <div>
          <div styleName="storycards-row">
            <div styleName="card-with-separator first-card" className="first-card">
              <StoryCard
                story={firstStory}
                headerLevel="2"
                aspectRatio={[
                  [16, 9],
                  [16, 9]
                ]}
              >
                <HeroImage
                  story={firstStory}
                  aspectRatio={[
                    [16, 9],
                    [16, 9]
                  ]}
                />
                <div className="card-content">
                  <SectionTag story={firstStory} />
                  <Headline story={firstStory} headerLevel="2" />
                </div>
              </StoryCard>
              {secondStory && (
                <StoryCard story={secondStory} headerLevel="2" border="bottom" isHorizontal aspectRatio={[[4, 3]]}>
                  <HeroImage story={secondStory} aspectRatio={[[4, 3]]} />
                  <div styleName="small-card-content">
                    <SectionTag story={secondStory} />
                    <Headline story={secondStory} headerLevel="6" />
                  </div>
                </StoryCard>
              )}
              {thirdStory && (
                <StoryCard story={thirdStory} headerLevel="2" border="bottom" isHorizontal aspectRatio={[[4, 3]]}>
                  <HeroImage story={thirdStory} aspectRatio={[[4, 3]]} />
                  <div styleName="small-card-content">
                    <SectionTag story={thirdStory} />
                    <Headline story={thirdStory} headerLevel="6" />
                  </div>
                </StoryCard>
              )}
            </div>
            <div styleName="card-with-separator second-card" className="second-card">
              {restOftheStories.length > 0 &&
                restOftheStories.slice(0, 6).map(item => {
                  return (
                    <StoryCard
                      story={item}
                      headerLevel="2"
                      border="bottom"
                      isHorizontal
                      aspectRatio={[[4, 3]]}
                      key={item.id}
                    >
                      <HeroImage story={item} aspectRatio={[[4, 3]]} />
                      <div styleName="small-card-content">
                        <SectionTag story={item} />
                        <Headline story={item} headerLevel="6" />
                      </div>
                    </StoryCard>
                  );
                })}
            </div>
            <div styleName="card-with-separator third-card" className="third-card">
              {/*restOftheStories.length > 0 &&
                restOftheStories.slice(6, 9).map(item => {
                  return (
                    <StoryCard
                      story={item}
                      headerLevel="2"
                      border="bottom"
                      isHorizontal
                      aspectRatio={[[4, 3]]}
                      key={item.id}
                    >
                      <HeroImage story={item} aspectRatio={[[4, 3]]} />
                      <div styleName="small-card-content">
                        <SectionTag story={item} />
                        <Headline story={item} headerLevel="6" />
                      </div>
                    </StoryCard>
                  );
                })*/}
                </div>
          </div>
        </div>

      </div>
      {/*adConfig && (<div styleName="ads">Ad Container in ThreeColFifteenStoriesRow</div>)*/}
    </div>
  );
};

ThreeColFifteenStoriesRow.propTypes = {
  collection: object,
  dfpAd: bool
};

export { ThreeColFifteenStoriesRow };

ThreeColFifteenStoriesRow.storyLimit = 22;

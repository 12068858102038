import React from "react";
import get from "lodash/get";
import { object } from "prop-types";

import { collectionToStories } from "@quintype/components";

import CardImageType4 from "../../molecules/card-image-type-4";
import CardImageType5 from "../../molecules/card-image-type-5";

import "./four-small-image-news-cols.m.css";
import { SectionCategoryTitle } from "../../molecules/section-category-title";

const FourSmallImgNewsCols = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
        {showSection &&<div><SectionCategoryTitle name={collection.name} /></div>}
          <div styleName="item-wrapper1">
            {storiesFromCollection.length >= 1 &&
              storiesFromCollection.slice(0, 4).map((item, index) =>
                <React.Fragment key={index}>
                  <div styleName="item" className="item" key={item.id}>
                    <CardImageType4 story={item} />
                  </div>
                </React.Fragment>
              )
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

FourSmallImgNewsCols.propTypes = {
  collection: object
};
export { FourSmallImgNewsCols };

FourSmallImgNewsCols.storyLimit = 22;

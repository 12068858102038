import React from "react";
import get from "lodash/get";
import { object } from "prop-types";

import { collectionToStories, Link } from "@quintype/components";

import CardImageType8 from "../../molecules/card-image-type-8";
import CardImageType4 from "../../molecules/card-image-type-4";
import "./one-big-3-small-news-rows-no-img.m.css";
import TimeStamp from "../../atoms/time-stamp";
import { SectionCategoryTitle } from "../../molecules/section-category-title";

const OneBigThreeSmallNewsRowsNoImg = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  //const storiesFromCollection = collection.items.filter(item => item.type === "story");
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }

  const [firstStory, ...restOftheStories] = storiesFromCollection || [];

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
          {showSection &&<div><SectionCategoryTitle name={collection.name} /></div>}
            <div styleName="first-story first-story1">
              <CardImageType4 story={firstStory} showSubHeadline ={false}/>
            </div>
            <div styleName="item-wrapper1">

              {restOftheStories.length >= 1 &&
                restOftheStories.slice(0,3).map((item, index) =>

                  <React.Fragment key={index}>
                    {/*<div styleName="item" className="item" key={item.id}>
                     <CardImageType8 story={item} showSubHeadline ={false}/>
              </div>*/}
                      <div className="main-headline item" styleName="story-content">
                            <div styleName="wrapper-inside" className="wrapper-inside">

                              <h2 styleName="" className="headline-layout4">
                              <Link className="main-headline-layout1 layout4" href={`/${item.slug}`}><span styleName="span-newest">{`${item.sections[0].name} | `}</span>{item.headline}</Link>
                              </h2>
                              <div styleName="section-time-wrapper">
                                <div className="time-stamp-layout1">
                                  <TimeStamp timeStamp={item["published-at"]} />
                                </div>
                                {(item.access === "login" || item.access === "subscription") &&
                                  <div>
                                      <i className="ri-star-fill"></i>
                                  </div>}
                              </div>
                            </div>
                          </div>
                  </React.Fragment>

                )
              }
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

OneBigThreeSmallNewsRowsNoImg.propTypes = {
  collection: object
};

export { OneBigThreeSmallNewsRowsNoImg };

OneBigThreeSmallNewsRowsNoImg.storyLimit = 22;

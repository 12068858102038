import get from "lodash/get";
import { object } from "prop-types";
import React from "react";
import DfpAd from "../../dfp-ad";
import CardImageType2 from "../../molecules/card-image-type-2";
import { RowTitle } from "../../molecules/row-title";
import "./two-col-with-seven-stories-row.m.css";

const TwoColWithSevenStoriesRow = ({ collection = {} }) => {
  //console.log("collection", collection)

  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const storiesFromCollection = collection.items.filter(item => item.type === "story");
  if (storiesFromCollection.length < 1) {
    return null;
  }

  const [firstStory, ...restOftheStories] = storiesFromCollection;

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <RowTitle collection={collection} />
          <div styleName="inner-wrapper">
            <div styleName="first-story">
              <CardImageType2 story={firstStory} showSection />
            </div>
            <div styleName="item-wrapper">
              {restOftheStories.length >= 1 &&
                restOftheStories.slice(0, 6).map(item => {
                  return (
                    <div styleName="item" key={item.id}>
                      <CardImageType2 story={item} />
                    </div>
                  );
                })}
            </div>
          </div>
          {/*adConfig && (<div styleName="ads">Ad Space TwoColWithSevenStoriesRow</div>)*/}
        </div>
      </div>

    </React.Fragment>
  );
};

TwoColWithSevenStoriesRow.propTypes = {
  collection: object
};

export { TwoColWithSevenStoriesRow };

TwoColWithSevenStoriesRow.storyLimit = 22;

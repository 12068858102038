import React, { useState } from "react";
import { object, number, array } from "prop-types";
import { Link } from "@quintype/components";
import { SectionTag } from "@quintype/arrow";
import assetify from "@quintype/framework/assetify";
import CardImage from "../../atoms/card-image";
import Photo from "../../basic/images/photo.svg";
import Video from "../../basic/images/video.svg";
import "./card-image-type-native.m.css";
import { SectionCategoryTitle } from "../section-category-title";


const CardImageTypeNative = ({ story, showSection=false, showSubHeadline = false, widths = [250, 375] }) => {
  const storyObj = story.story ? story.story : story;
  const nativeAd = story.metadata["sponsored-by"];
  const paaaihe = storyObj.metadata?storyObj.metadata["pääaihe"]:undefined;
  const accessType = storyObj.access;
  const imgS3keys= storyObj["hero-image-s3-key"];
  return (
    <Link href={`/${storyObj.slug}`}>
      <div styleName="wrapper" className="card-image-type-native">
            {/*showSection && !nativeAd &&<SectionCategoryTitle name={storyObj.sections[0].name} />*/}
            {imgS3keys !==null && <div>
              <CardImage
              story={storyObj}
              defaultWidth={259}
              aspectRatio={[3, 2]}
              imageType="image3x2"
              widths={widths}
            />
        </div>}
      </div>

      <div styleName="card-image-type-native-texts">
      <div className="main-headline" styleName={imgS3keys? "story-content":"no-img"}>
          <div styleName="wrapper-inside" className="wrapper-inside">
              <p styleName="aihesana"> MAINOS <span styleName="aihesana-span"> Native ad position </span></p>
              <h2 styleName="headline" className="main-headline-layout1 layout4">
                <span styleName="span-newest">{paaaihe && `${paaaihe} | `}</span>
                {storyObj.headline}
              </h2>
              {showSubHeadline &&<h2 styleName="headline" className="subheadline">
                {" "}
                <Link className="subheadline" href={`/${storyObj.slug}`}>{storyObj.subheadline}</Link>
              </h2>}
          </div>
          </div>
      </div>
    </Link>
  );
};

export default CardImageTypeNative;

CardImageTypeNative.propTypes = {
  story: object,
  headlineLimit: number,
  widths: array
};

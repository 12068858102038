import React from "react";
import get from "lodash/get";
import { object } from "prop-types";

import { collectionToStories } from "@quintype/components";

import CardImageType3 from "../../molecules/card-image-type-3";

import "./large-news-img-card.m.css";

const LargeNewsImgCard = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }

  const [firstStory, ...restOftheStories] = storiesFromCollection || [];
  //console.log(restOftheStories);
  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper">
            <div styleName="first-story">
              <CardImageType3 story={firstStory} showSection />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

LargeNewsImgCard.propTypes = {
  collection: object
};

export { LargeNewsImgCard };

LargeNewsImgCard.storyLimit = 12;

import React, { useState } from "react";
import { object, number, string, func, Boolean } from "prop-types";
import _ from 'lodash';
import StoriesForLoadMore from "../stories-for-load-more";
import Pagination from "../../pages/pagination";
import "./load-more-search-results.m.css";
import PaginationStory from "../pagination-story";

const LoadMoreSearchResults = props => {

  const { stories } = props.data;
  console.log("storiesx loadmoresearchresults", stories);
 /* const ordered = _.sortBy(stories, [function (o) {
    return o["published-at"];
  }])
  const orderedStories = ordered.reverse();*/
  const [isActive, setActive] = useState(false);
  const [disActive, dsetActive] = useState(false);
  const dateClicks = 0;
  const relClicks = 0;

  // these handles are made so that font-weight will change when clicked. Do not remove.
  const handleDateToggle = () => {
    if (dateClicks == 0){
    dsetActive(true);
    setActive(false);}
    dateClicks++;
    console.log(dateClicks);
  };

  const handleRelToggle = () => {
    if (relClicks == 0){
    setActive(true);
    dsetActive(false);}
    relClicks++;
    console.log(relClicks);
  };

  return (
    <div>
      {stories.length ? (
        <div>
          <div styleName="search-sorting">
            {/*<div styleName="sort-secondrow">*/}
            <div styleName="">
              {/*<div styleName="sort-by">Järjestä: </div>*/}
              {/*<div styleName="sort-by-mobile">
                <label for="sortmethod">Järjestä: </label>
                  <select id="sortmethod" onChange={handleChange} value={selected}>
                  <option onClick={props.sortByDate} styleName="sort-option" value="pvm">Päivämäärän mukaan</option>
                  <option onClick={props.sortByRelativity} styleName="sort-option" value="rel">Osuvuuden mukaan</option>
                  </select>
              </div>*/}
             {/*<div id="sort-by-date" styleName={`sort-by-${disActive ? "date-active" : "date"}`} onClick={() => {props.sortByDate(); handleDateToggle();}}>Päivämäärän mukaan</div>*/}
              {/*<div id="sort-by-relativity" styleName={`sort-by-${isActive ? "relativity-active" : "relativity"}`} onClick={() => {props.sortByRelativity(); handleRelToggle();}}>Osuvuuden mukaan</div>*/}
            </div>
          </div>
          {!props.toggleBoth ? props.byRelativity
            ? <div>
              <Pagination
                stories={props.storiesData}
                PaginationComponent={PaginationStory}
                pageLimit={1}
                storyLimit={props.storyLimit}
              />
            </div> :
            <Pagination
              stories={props.orderedStories}
              PaginationComponent={PaginationStory}
              pageLimit={1}
              storyLimit={props.storyLimit}
            />
            : props.isOrdered ?
              <Pagination
                stories={props.orderedStories}
                PaginationComponent={PaginationStory}
                pageLimit={1}
                storyLimit={props.storyLimit}
              /> :
              <Pagination
                stories={stories}
                PaginationComponent={PaginationStory}
                pageLimit={1}
                storyLimit={props.storyLimit}
              />}
          <div>
          </div>
        </div>
      ) : (
        <h3 styleName="noresult-headline">Haullasi ei löytynyt tuloksia, muuta hakusanaa ja kokeile uudelleen.</h3>
      )}
    </div>
  );
}
LoadMoreSearchResults.propTypes = {
  data: object,
  itemsToLoad: number,
  query: string,
  isOrdered: Boolean,
  sortByDate: func,
  sortByRelativity: func,
  storiesData: object,
  byRelativity: Boolean,
  storyLimit:number,
};

export default LoadMoreSearchResults;

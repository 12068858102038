import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { SectionTag } from "@quintype/arrow";
import { collectionToStories } from "@quintype/components";
//import Suosituimmat from "../../story-templates/suosituimmat";
import "./big-images-with-ads.m.css";
import CardImageType5 from "../../molecules/card-image-type-5";
import Pointti from "../../pointti";

const storiesToBigImagesWithAds = storiesFromCollection => {
  return storiesFromCollection.slice(0, 15).map((item, index) => {
    return (
      <React.Fragment key={index}>
        <div styleName="desktop-ad-slots">
          {index === 3 && (
            <div styleName="ad-container-between-articles">
              <div styleName="sidebar-ad-mobile" id="sas_63337"></div>
              {/*<div styleName="newest-category-mobile">
                <Suosituimmat />
              </div>*/}
              <div id="sas_106329" styleName="display-in-screen indexes"></div>
              <div id="sas_106330" styleName="mobile-ad indexes"></div>
            </div>
          )}
          {index === 5 && (
            <div styleName="ad-container-between-articles">
              <div id="sas_63231" styleName="display-in-screen indexes"></div>
              <div id="sas_63352" styleName="mobile-ad indexes"></div>
            </div>
          )}
          {index === 7 && (
            <div styleName="ad-container-between-articles">
              <div id="sas_106331" styleName="display-in-screen indexes"></div>
              <div id="sas_106332" styleName="mobile-ad indexes"></div>
              <div styleName="pointti-ad indexes">
                <Pointti />
              </div>
            </div>
          )}
          {index === 9 && (
            <div styleName="ad-container-between-articles">
              <div id="sas_63232" styleName="display-in-screen indexes"></div>
              <div id="sas_63353" styleName="mobile-ad indexes"></div>
            </div>
          )}
        </div>
        <div styleName="item-wrapper1">
          <React.Fragment key={index}>
            <div styleName="item" key={item.id}>
              {/*<SectionTag story={item} />*/}
              <CardImageType5 story={item} showSubHeadline={false} />
            </div>
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  });
};

const BigImagesWithAds = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const storiesFromCollection = collectionToStories(collection);

  if (storiesFromCollection.length < 1) {
    return null;
  } else {
    return (
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">{storiesToBigImagesWithAds(storiesFromCollection)}</div>
        </div>
      </div>
    );
  }
};

BigImagesWithAds.propTypes = {
  collection: object
};

export { BigImagesWithAds };

import { useEffect, useState } from "react";
const useDOMrender = (dependsOn) => {
  const [domRendered, setDomRendered] = useState(false);
  useEffect(()=>{
    dependsOn&&setDomRendered(true);
  },[dependsOn])

  return [domRendered]
}
export default useDOMrender;

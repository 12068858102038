import React from "react";
import get from "lodash/get";
import { object } from "prop-types";

import { collectionToStories } from "@quintype/components";
import CardImageType5 from "../../molecules/card-image-type-5";

import "./right-now.m.css";

const RightNow = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }
  const [firstStory, ...restOftheStories] = storiesFromCollection || [];
  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
            {firstStory && <div styleName="first-story first-story1">
              <CardImageType5 story={firstStory} showSection={showSection} showSubHeadline={false} />
            </div>}
          </div>
          <div styleName="ads" id="sas_76185"></div>
        </div>
      </div>

    </React.Fragment>
  );
};

RightNow.propTypes = {
  collection: object
};
export { RightNow };

RightNow.storyLimit = 22;

import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { collectionToStories } from "@quintype/components";
import { StoryCard } from "@quintype/arrow";
import "./four-col-four-rows-ten-stories.m.css";
import CardImageType1 from "../../molecules/card-image-type-1";
import CardImageType2 from "../../molecules/card-image-type-2";
import CardImageType3 from "../../molecules/card-image-type-3";

const FourColFourRowsTenStories = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }

  const [firstStory, secondStory, ...restOftheStories] = storiesFromCollection || [];

  //console.log("restOftheStories", (storiesFromCollection))
  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper">
            <div styleName="first-story">
              <CardImageType2 story={firstStory} showSection />
            </div>
            <div styleName="item-wrapper">
              {restOftheStories.length >= 1 &&
                restOftheStories.slice(0, 4).map(item => {
                  return (
                    <div styleName="item" key={item.id}>
                      <CardImageType2 story={item} />
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div styleName="inner-wrapper2">
            <div styleName="item-wrapper2">
              {restOftheStories.length >= 1 &&
                restOftheStories.slice(4, 8).map(item => {
                  return (
                    <div styleName="item" key={item.id}>
                      <CardImageType2 story={item} />
                    </div>
                  );
                })}
            </div>
            <div styleName="first-story">
              <CardImageType2 story={secondStory} showSection />
            </div>
          </div>
          {/*adConfig && (<div styleName="ads">Ad Space in FourColFourRowsTenStories</div>)*/}
        </div>
      </div>

    </React.Fragment>
  );
};

FourColFourRowsTenStories.propTypes = {
  collection: object
};

export { FourColFourRowsTenStories };

FourColFourRowsTenStories.storyLimit = 12;

import React, { useState } from "react";
import "./pagination.m.css";

const Pagination = ({ stories, PaginationComponent, pageLimit, storyLimit }) => {
  const [pages] = useState(Math.round(stories.length / storyLimit));
  const [currentPage, setCurrentPage] = useState(1);
  const [containData, setContainsData] = useState(0);
  //console.log("Pagination stories", stories, pageLimit, storyLimit);
  function nextPage() {
    setCurrentPage((page) => page + 1);
  }

  function previousPage() {
    setCurrentPage((page) => page - 1);
  }

  function onPageChange(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const paginatedData = () => {
    const startIndex = currentPage * storyLimit - storyLimit;
    const endIndex = startIndex + storyLimit;

    return stories.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <div>
      <div>
        {paginatedData().length > 0 && paginatedData().map((d, idx) => (
          <PaginationComponent key={idx} story={d} />
        ))}
      </div>
      <div styleName="pagination">
        <button
          onClick={previousPage}
          styleName={`prev ${currentPage === 1 ? 'disabled' : ''}`}
        >
          &lt;&lt; Edellinen
        </button>
        {getPaginationGroup().map((item, index) => (
          <button
            key={index}
            onClick={onPageChange}
            styleName={`pagination-item ${currentPage === item ? 'active' : ""}`}
          >
            <span>
              {paginatedData().length > storyLimit - 1 ? item : item}
            </span>
          </button>
        ))}
        {paginatedData().length > storyLimit - 1 && <button
          onClick={nextPage}
          styleName={`next ${currentPage === pages ? 'disabled' : ''}`}
        >
          Seuraava &gt;&gt;
        </button>}
      </div>
    </div>
  );
}
export default Pagination;

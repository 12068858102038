/** Ei käytössä **/
import { Headline, HeroImage, StoryCard } from "@quintype/arrow";
import get from "lodash/get";
import PT from "prop-types";
import React from "react";
import DfpAd from "../../dfp-ad";
import "./sidebar.m.css";

const SideBar = ({ trendingCollection = {} }) => {
  const collectionItem = get(trendingCollection, ["collection", "items"], []);

  if (collectionItem.length < 1) {
    return null;
  }

  return (
    <div>
      <h3 styleName="main-headline">Suosituimmat tarinat</h3>
      {trendingCollection.collection.items.slice(0, 6).map((item, index) => {
        return (
          <div styleName="side-bar" key={index}>
            <StoryCard story={item.story} isHorizontal>
              <HeroImage story={item.story} isHorizontal aspectRatio={[[16, 9]]} />
              <div styleName="story-content">
                <Headline story={item.story} />
              </div>
            </StoryCard>
          </div>
        );
      })}
      <div styleName="dfp-ad">
        <DfpAd adtype="xxxx" slotId="sidebar" />
      </div>
    </div>
  );
};

SideBar.propTypes = {
  trendingCollection: PT.object
};

export default SideBar;

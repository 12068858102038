
import { array, string } from "prop-types";
import React from "react";
import CardImageType5 from "../../molecules/card-image-type-5";
import "./right-now.m.css";

const RightNow = ({ rightNow = [], id = "" }) => {
  //console.log("rightNow not rendering", rightNow)
  if (rightNow.length < 1) {
    return null;
  }
  return (
    <React.Fragment>
        <div styleName="container">
          <div styleName="inner-wrapper1">
            <div styleName="first-story">
             <CardImageType5 story={rightNow[0].story} showSection ={false} />
            </div>
            {/*<div styleName="ads" id="sas_76185"></div>*/}
          </div>
        </div>
    </React.Fragment>
  );
};

RightNow.propTypes = {
  rightNow: array,
  id: string,
};

export default RightNow;

import React from "react";
import { object, number, array } from "prop-types";
import { Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import { SectionTag } from "@quintype/arrow";

import CardImage from "../../atoms/card-image";
import Photo from "../../basic/images/photo.svg";
import Video from "../../basic/images/video.svg";

import "./card-image-type-2.m.css";



const CardImageType2 = ({ story, showSection=false, widths = [250, 375] }) => {
  const storyObj = story.story ? story.story : story;

  const getIcon = type => {
    if (type === "photo") {
      return (
        <div styleName="icon">
           <img src={`${assetify(Photo)}`} alt="photo"/>
        </div>
      );
    } else if (type === "video") {
      return (
        <div styleName="icon">
         <img src={`${assetify(Video)}`} alt="video"/>
        </div>
      );
    }
  };

  return (
    <Link href={`/${storyObj.slug}`}>
      <div styleName="wrapper" className="card-image-type-2">
        <CardImage
          story={storyObj}
          defaultWidth={259}
          aspectRatio={[16, 9]}
          imageType="image16x9"
          widths={widths}
        />
        <div styleName="info" className="info">
         {getIcon(storyObj["story-template"])}
        </div>
      </div>
      {/*<div className="story-content">
      {showSection && <SectionTag story={storyObj} />}
      <h3 styleName="headline" className="story-headline">{storyObj.headline}</h3>
      </div>*/}
      <div className="story-content" styleName="story-content">
          <h2 styleName="main-headline" className="main-headline headline-section">{showSection && <SectionTag story={storyObj} />}{storyObj.headline}</h2>
      </div>
    </Link>
  );
};

export default CardImageType2;

CardImageType2.propTypes = {
  story: object,
  headlineLimit: number,
  widths: array
};

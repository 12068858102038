import React from "react";
import get from "lodash.get";
import PropTypes from "prop-types";
import { collectionToStories } from "@quintype/components";

import { Author, Headline, Subheadline, HeroImage, StoryCard, StorycardContent } from "@quintype/arrow";
import { AuthorImage } from "../../atoms/author-image/index";

import { RowTitle } from "../../molecules/row-title";

import "./three-col-five-story.m.css";

const SmallStoryCard = ({ data, border }) => {
  const authorName = get(data, ["authors", "0", "name"], data["author-name"]);
  return (
    <div styleName="small-card-top-container">
      <StoryCard border={border} story={data}>
        <div styleName="small-card-container">
          <div styleName="small-card-image-container">
            <AuthorImage width="64" story={data} />
          </div>
          <div>
            <h6 styleName="author-name"> {authorName}</h6>
            <Headline story={data} headerLevel={6} />
          </div>
        </div>
      </StoryCard>
    </div>
  );
}

const OpinionPolls = ({ collection = {} }) => {
  const items = collectionToStories(collection);
  const border = "full";
  if (items.length < 1) {
    return null;
  }
  return (
    <section styleName="wrapper">
      <div styleName="inner-wrapper">
        <RowTitle collection={collection} />
        <div className="full-width-with-padding arrow-component" s>
          <div styleName="content-container">
            <div styleName="small-cards-container">
              {items.slice(0, 2).map((data, index) => {
                return <SmallStoryCard data={data} key={index} border={border} />;
              })}
            </div>
            <div styleName="small-cards-container">
              {items.slice(2, 4).map((data, index) => {
                return <SmallStoryCard data={data} key={index} border={border} />;
              })}
            </div>
            <div styleName="small-cards-container">
              {items.slice(4, 6).map((data, index) => {
                return <SmallStoryCard data={data} key={index} border={border} />;
              })}
            </div>
            <div styleName="small-cards-container">
              {items.slice(6, 8).map((data, index) => {
                return <SmallStoryCard data={data} key={index} border={border} />;
              })}
            </div>
            <div styleName="small-cards-container">
              {items.slice(8, 10).map((data, index) => {
                return <SmallStoryCard data={data} key={index} border={border} />;
              })}
            </div>
            <div styleName="small-cards-container">
              {items.slice(10, 12).map((data, index) => {
                return <SmallStoryCard data={data} key={index} border={border} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

OpinionPolls.propTypes = {
  collection: PropTypes.object.isRequired,
  config: PropTypes.shape({
    theme: PropTypes.string,
    border: PropTypes.string,
    collectionNameTemplate: PropTypes.string,
    showSubHeadline: PropTypes.bool,
    showRowTitle: PropTypes.bool,
    footerSlotConfig: PropTypes.object,
    cardBackground: PropTypes.string
  })
};

SmallStoryCard.propTypes = {
  data: PropTypes.object.isRequired,
  border: PropTypes.string
};

export { OpinionPolls };

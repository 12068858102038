import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { collectionToStories } from "@quintype/components";
import { StoryCard } from "@quintype/arrow";

import CardImageType2 from "../../molecules/card-image-type-2";
import CardImageType1 from "../../molecules/card-image-type-1";
import CardImageType3 from "../../molecules/card-image-type-3";

import "./one-col-4-stories-no-ad.m.css";
const OneColFourStoriesNoAd = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }

  const [firstStory, ...restOftheStories] = storiesFromCollection || [];

  return (
    <React.Fragment>

        <div styleName={`container ${theme ? "dark-bg" : "light-bg"}`} className="one-col-4-stories-no-container">
          <div styleName="inner-wrapper">
            <div styleName="first-story">
              <CardImageType2 story={firstStory} showSection />
            </div>
            <div styleName="item-wrapper">
              {restOftheStories.length >= 1 &&
                restOftheStories.slice(0,3).map((item, index) =>
                  <React.Fragment key={index}>
                    <div styleName="item" key={item.id}>
                      <CardImageType1 story={item} />
                    </div>
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </div>

    </React.Fragment>
  );
};

OneColFourStoriesNoAd.propTypes = {
  collection: object
};

export { OneColFourStoriesNoAd };

OneColFourStoriesNoAd.storyLimit = 12;

import get from "lodash/get";
import React from "react";
import PT from "prop-types";
import DfpAd from "../../dfp-ad";
import CarouselSlider from "../../molecules/carousel-slider";
import { RowTitle } from "../../molecules/row-title";
import "./story-card-slider.m.css";

const StoryCardSlider = ({ collection }) => {
  const storiesFromCollection = collection.items.filter(item => item.type === "story");
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  return (
    <div styleName="wrapper">

      {/*<RowTitle collection={collection} />*/}
      <CarouselSlider
        collection={collection}
        options={{
          type: `${storiesFromCollection.length <= 4 ? "slider" : "carousel"}`,
          perView: 3,
          gap: 10,
          breakpoints: { 480: { perView: 1, peek: { before: 0, after: 80 } } }
        }}
      />
        {/*adConfig && (<div styleName="ads"> Ad Space in StoryCardSlider</div>)*/}
    </div>

  );
};

StoryCardSlider.propTypes = {
  collection: PT.object
};

export { StoryCardSlider };

import React, { useState } from "react";
import get from "lodash/get";
import { object } from "prop-types";

import { collectionToStories, Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import { SectionTag } from "@quintype/arrow";

import CardImageType5 from "../../molecules/card-image-type-5";
import CardImageType8 from "../../molecules/card-image-type-8";
import CardImageType4 from "../../molecules/card-image-type-4";
import StarShowHide from "../../atoms/star-show-hide";
import Star from "../../basic/images/star-fill.svg";
import TimeStamp from "../../atoms/time-stamp";

import "./five-small-img-news-rows.m.css";
import { SectionCategoryTitle } from "../../molecules/section-category-title";

const FiveSmallImgNewsRows = ({ collection }) => {
  //console.log("mycollections", collection)
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  const storiesFromCollection = collectionToStories(collection);

  if (storiesFromCollection.length < 1) {
    return null;
  }

  const [firstStory, ...restOftheStories] = storiesFromCollection || [];
  const [isLogged, setIsLogged] = useState(false);

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
        {showSection &&<div><SectionCategoryTitle name={collection.name} /></div>}
          <div styleName="item-wrapper1">
            {restOftheStories.length >= 1 &&
              restOftheStories.slice(0, 5).map((item, index) =>
                <React.Fragment key={index}>
                  {item["hero-image-s3-key"] !== null ?
                    <div styleName="item" className="item" key={item.id}>
                      <CardImageType8 story={item} showSubHeadline={false} />
                    </div> :

                    <div className="main-headline item" styleName={item["hero-image-s3-key"] ? "story-content" : "story-content-no-img"}>
                      <Link href={`/${item.slug}`}>
                      <div styleName="wrapper-inside" className="wrapper-inside">

                        <h2 styleName="" className="headline-layout4 main-headline-layout1 layout4">
                          <span styleName="span-newest">{item.metadata && item.metadata["pääaihe"] && `${item.metadata["pääaihe"]} | `}</span>
                          {item.headline}
                        </h2>
                        <div styleName="section-time-wrapper">
                          <div className="time-stamp-layout1">
                            <TimeStamp timeStamp={item["published-at"]} />
                          </div>
                          <StarShowHide accessType={item.access} />
                        </div>
                      </div>
                      </Link>
                    </div>

                  }
                </React.Fragment>
              )
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

FiveSmallImgNewsRows.propTypes = {
  collection: object
};

export { FiveSmallImgNewsRows };

FiveSmallImgNewsRows.storyLimit = 22;

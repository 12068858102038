import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { SectionTag } from "@quintype/arrow";
import { collectionToStories, Link } from "@quintype/components";

import CardImageType2 from "../../molecules/card-image-type-2";

import "./three-small-news-cards-with-img.m.css";
import CardImageType4 from "../../molecules/card-image-type-4";
const ThreeSmallNewsCardImg = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const storiesFromCollection = collectionToStories(collection);

  if (storiesFromCollection.length < 1) {
    return null;
  }
  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
           {/* <div id="sas_63333" styleName="ads"></div>*/}
            <div styleName="item-wrapper1">
              {storiesFromCollection.length >= 1 &&
                storiesFromCollection.slice(0,3).map((item, index) =>
                  <React.Fragment key={index}>
                    <div styleName="item" key={item.id}>
                    {/*<SectionTag story={item} />*/}
                      <CardImageType4 story={item}/>
                    </div>
                  </React.Fragment>
                )
              }
            </div>
           {/* <div id="sas_76186" styleName="empty-ad ads">Ad space in NoImgNsmlImgCards</div>*/}
           {/*adConfig && <div styleName="empty-ad">Ad Position 4</div>*/}
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

ThreeSmallNewsCardImg.propTypes = {
  collection: object
};

export { ThreeSmallNewsCardImg };

ThreeSmallNewsCardImg.storyLimit = 12;
